import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import { Config } from '../config/config'

import Layout from '../layouts'

import frontpageImage1 from '../assets/images/frontpage-image-1-min.jpg'
import frontpageImage2 from '../assets/images/frontpage-image-2-min.jpg'
import defaultNewsImage from '../assets/images/news-default-image.jpg'

import '../scss/home.scss'
import people from "../data/people.yaml"
import partners from "../data/partners.yaml"
import publications from "../../static/all-results.yaml"


// import publications from "../../cristin/publications.yaml"
// import publications from "../../cristin/presenta.yaml"

const IndexPage = ({ data }) => {
  const { allMarkdownRemark } = data

  const news = allMarkdownRemark.edges.filter(edge => edge.node.frontmatter.type === 'article' && edge.node.frontmatter.is_published === true)
  const results = publications.results;
  const industrialPartners = partners.industrialPartners;

  return (
    <Layout>
      <div className="home">

        <Helmet>
          <title>DigiWells – Digitalize, Drill, Geosteer</title>
          <meta property="og:title" content="DigiWells – Digitalize, Drill, Geosteer" />
          <meta name="description" content="SFI DigiWells is a center for research-based innovation. We are developing new knowledge that will help to drill and position wells in the optimal manner." />
          <meta property="og:description" content="SFI DigiWells is a center for research-based innovation. We are developing new knowledge that will help to drill and position wells in the optimal manner." />
          <meta name="image" content={frontpageImage1} />
          <meta property="og:image" content={frontpageImage1} />
        </Helmet>

        <div className="container container-8">
          <h1 className="title">DigiWells</h1>
          <p className="subtitle">Digitalize &middot; Drill &middot;  Geosteer</p>
        </div>

        <div className="images-wrapper">
          <div className="container container-10">
            <div className="images">
              <div className="image">
                <img alt="" src={frontpageImage1} />
              </div>
              <div className="image">
                <img alt="" src={frontpageImage2} />
              </div>
            </div>
          </div>
        </div>

        <div className="container container-8">
          <div className="image-description">
            {/* DigiWells: Digital Well Centre for Value Creation, Competitiveness, and Minimum Environmental Footprint 
          is a centre for research-based innovation (SFI) awarded by the Research Council of Norway. 
          We are developing new knowledge that will help to drill and position wells in an optimal manner. */}
            SFI DigiWells is a center for research-based innovation funded by the Research Council of Norway and the industrial partners from 2020 to 2028. We are developing new knowledge that will help to drill and position wells in the optimal manner. Our main objectives are value creation, safe operation, and minimum environmental footprint.
          </div>
        </div>

        <div className="in-numbers">
          <div className="container container-6">
            <h2>DigiWells in numbers</h2>
            <div className="numbers-wrapper">
              <div className="number">
                <div className="number-number">{
                  partners.industrialPartners.length +
                  partners.researchPartners.length +
                  partners.industrialPartnersInKind.length + 
                  partners.international.length
                }
                </div>
                <div><a className="number-text" href="about/consortium/">partners</a></div>
              </div>

              <div className="number">
                <div className="number-number" href="about/key-researchers/">
                  {
                    people.keyResearchers.map(function (keyResearchersGroup) {
                      return keyResearchersGroup.people.length;
                    }).reduce(function (a, b) {
                      return a + b;
                    },
                      0)
                  }
                </div>
                <div><a className="number-text" href="about/key-researchers/">researchers</a></div>
                {/* <div><a className="number-text" href="news/first-five-phd-positions/">Join us!</a></div> */}
              </div>
              <div className="number">
                <div className="number-number">{results.length}</div>
                <div><a className="number-text" href="results/">results</a></div>
                {/* <div className="number-number">{results.length}</div>
                <div><a className="number-text" href="results/publications/">results</a></div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="recent-news">
          <div className="container container-6">
            <h2>Recent news</h2>
            <div className="list">
              {
                news.map(function (edge, index) {
                  const item = edge.node.frontmatter
                  let slug = edge.node.fields.slug
                  if (item.redirect) {
                    slug = item.redirect;
                  }

                  if (index < 3) {
                    return (
                      <a className="list-item article" href={slug} key={index}>
                        <div className="list-item-image" style={{ backgroundImage: `url(${item.list_image ? item.list_image : defaultNewsImage})` }}>
                        </div>

                        <div className="list-item-text">
                          <h3 className="list-item-title small">
                            {item.title}
                          </h3>
                          <div className="list-item-description small">
                            {item.short_description}
                          </div>
                        </div>
                        <div className="list-item-icon">
                          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="30" cy="30" r="30" fill="#222742" fillOpacity="0.1" />
                            <path d="M34.0003 20.6666L32.1203 22.5466L38.227 28.6666H16.667V31.3333H38.227L32.107 37.4533L34.0003 39.3333L43.3337 30L34.0003 20.6666Z" fill="#222742" />
                          </svg>

                        </div>
                      </a>
                    )
                  }
                  else {
                    return "";
                  }
                })
              }

            </div>
            <p>
              <a className="more-news" href="/news">More news</a>
            </p>
          </div>
        </div>

      </div>
    </Layout>
  )
}

// Query
export const query = graphql`
  query HomePageQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            is_published
            type
            short_description
            list_image
            redirect
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default IndexPage
